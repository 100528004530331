import { FC } from 'react';
import { IconProps } from '.';

export const IconImportDocuments: FC<IconProps> = ({ width = '188px', height = '96px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 188 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M121.935 96H71.0991C69.7367 95.9984 68.4306 95.4562 67.4672 94.4922C66.5039 93.5283 65.962 92.2214 65.9604 90.8581V23.5053C65.962 22.1421 66.5039 20.8352 67.4672 19.8712C68.4306 18.9073 69.7367 18.3651 71.0991 18.3635H121.935C123.297 18.3651 124.604 18.9073 125.567 19.8712C126.53 20.8352 127.072 22.1421 127.074 23.5053V90.8581C127.072 92.2214 126.53 93.5283 125.567 94.4923C124.604 95.4562 123.297 95.9984 121.935 96Z"
        className="text-background"
        fill="currentColor"
      />
      <path
        d="M118.362 91.7145H74.6725C73.4628 91.7131 72.3031 91.2317 71.4477 90.3758C70.5924 89.52 70.1112 88.3595 70.1099 87.1491V27.2143C70.1112 26.0039 70.5924 24.8435 71.4477 23.9876C72.3031 23.1317 73.4628 22.6503 74.6725 22.6489H118.362C119.571 22.6503 120.731 23.1317 121.586 23.9876C122.442 24.8435 122.923 26.0039 122.924 27.2143V87.1491C122.923 88.3595 122.442 89.52 121.586 90.3758C120.731 91.2317 119.571 91.7131 118.362 91.7145Z"
        className="text-surface"
        fill="currentColor"
      />
      <path
        d="M114.003 34.0389H79.0309C78.881 34.0391 78.7325 34.0097 78.5939 33.9525C78.4553 33.8952 78.3294 33.8112 78.2233 33.7052C78.1172 33.5992 78.0331 33.4733 77.9756 33.3347C77.9182 33.1961 77.8887 33.0476 77.8887 32.8976C77.8887 32.7476 77.9182 32.5991 77.9756 32.4605C78.0331 32.3219 78.1172 32.196 78.2233 32.09C78.3294 31.984 78.4553 31.9 78.5939 31.8428C78.7325 31.7855 78.881 31.7561 79.0309 31.7563H114.003C114.153 31.7561 114.302 31.7855 114.44 31.8428C114.579 31.9 114.705 31.984 114.811 32.09C114.917 32.196 115.001 32.3219 115.059 32.4605C115.116 32.5991 115.146 32.7476 115.146 32.8976C115.146 33.0476 115.116 33.1961 115.059 33.3347C115.001 33.4733 114.917 33.5992 114.811 33.7052C114.705 33.8112 114.579 33.8952 114.44 33.9525C114.302 34.0097 114.153 34.0391 114.003 34.0389Z"
        className="text-background-variant"
        fill="currentColor"
      />
      <path
        d="M114.003 38.5487H79.0308C78.7282 38.5487 78.4381 38.4285 78.2242 38.2145C78.0103 38.0004 77.8901 37.7101 77.8901 37.4074C77.8901 37.1047 78.0103 36.8144 78.2242 36.6004C78.4381 36.3864 78.7282 36.2661 79.0308 36.2661H114.003C114.306 36.2661 114.596 36.3864 114.81 36.6004C115.024 36.8144 115.144 37.1047 115.144 37.4074C115.144 37.7101 115.024 38.0004 114.81 38.2145C114.596 38.4285 114.306 38.5487 114.003 38.5487Z"
        className="text-background-variant"
        fill="currentColor"
      />
      <path
        d="M94.1905 43.0583H79.0308C78.7282 43.0583 78.4381 42.938 78.2242 42.724C78.0103 42.5099 77.8901 42.2196 77.8901 41.9169C77.8901 41.6143 78.0103 41.324 78.2242 41.1099C78.4381 40.8959 78.7282 40.7756 79.0308 40.7756H94.1905C94.493 40.7756 94.7831 40.8959 94.997 41.1099C95.211 41.324 95.3311 41.6143 95.3311 41.9169C95.3311 42.2196 95.211 42.5099 94.997 42.724C94.7831 42.938 94.493 43.0583 94.1905 43.0583Z"
        className="text-background-variant"
        fill="currentColor"
      />
      <path
        d="M114.003 52.5032H79.0309C78.881 52.5034 78.7325 52.4741 78.5939 52.4168C78.4553 52.3595 78.3294 52.2755 78.2233 52.1695C78.1172 52.0635 78.0331 51.9376 77.9756 51.7991C77.9182 51.6605 77.8887 51.512 77.8887 51.362C77.8887 51.2119 77.9182 51.0634 77.9756 50.9248C78.0331 50.7863 78.1172 50.6604 78.2233 50.5544C78.3294 50.4484 78.4553 50.3644 78.5939 50.3071C78.7325 50.2499 78.881 50.2205 79.0309 50.2207H114.003C114.153 50.2205 114.302 50.2499 114.44 50.3071C114.579 50.3644 114.705 50.4484 114.811 50.5544C114.917 50.6604 115.001 50.7863 115.059 50.9248C115.116 51.0634 115.146 51.2119 115.146 51.362C115.146 51.512 115.116 51.6605 115.059 51.7991C115.001 51.9376 114.917 52.0635 114.811 52.1695C114.705 52.2755 114.579 52.3595 114.44 52.4168C114.302 52.4741 114.153 52.5034 114.003 52.5032Z"
        className="text-background-variant"
        fill="currentColor"
      />
      <path
        d="M114.003 57.0128H79.0308C78.7282 57.0128 78.4381 56.8926 78.2242 56.6786C78.0103 56.4645 77.8901 56.1742 77.8901 55.8715C77.8901 55.5688 78.0103 55.2785 78.2242 55.0645C78.4381 54.8505 78.7282 54.7302 79.0308 54.7302H114.003C114.306 54.7302 114.596 54.8505 114.81 55.0645C115.024 55.2785 115.144 55.5688 115.144 55.8715C115.144 56.1742 115.024 56.4645 114.81 56.6786C114.596 56.8926 114.306 57.0128 114.003 57.0128Z"
        className="text-background-variant"
        fill="currentColor"
      />
      <path
        d="M94.1906 61.5224H79.0308C78.7286 61.5219 78.4389 61.4015 78.2253 61.1875C78.0118 60.9735 77.8918 60.6835 77.8918 60.3811C77.8918 60.0786 78.0118 59.7886 78.2253 59.5746C78.4389 59.3606 78.7286 59.2402 79.0308 59.2397H94.1906C94.4931 59.2397 94.7832 59.36 94.9971 59.574C95.211 59.7881 95.3312 60.0784 95.3312 60.3811C95.3312 60.6838 95.211 60.9741 94.9971 61.1881C94.7832 61.4021 94.4931 61.5224 94.1906 61.5224Z"
        className="text-background-variant"
        fill="currentColor"
      />
      <path
        d="M114.003 70.9673H79.0309C78.881 70.9675 78.7325 70.9382 78.5939 70.8809C78.4553 70.8237 78.3294 70.7396 78.2233 70.6336C78.1172 70.5276 78.0331 70.4018 77.9756 70.2632C77.9182 70.1246 77.8887 69.9761 77.8887 69.8261C77.8887 69.6761 77.9182 69.5275 77.9756 69.3889C78.0331 69.2504 78.1172 69.1245 78.2233 69.0185C78.3294 68.9125 78.4553 68.8285 78.5939 68.7712C78.7325 68.714 78.881 68.6846 79.0309 68.6848H114.003C114.153 68.6846 114.302 68.714 114.44 68.7712C114.579 68.8285 114.705 68.9125 114.811 69.0185C114.917 69.1245 115.001 69.2504 115.059 69.3889C115.116 69.5275 115.146 69.6761 115.146 69.8261C115.146 69.9761 115.116 70.1246 115.059 70.2632C115.001 70.4018 114.917 70.5276 114.811 70.6336C114.705 70.7396 114.579 70.8237 114.44 70.8809C114.302 70.9382 114.153 70.9675 114.003 70.9673Z"
        className="text-background-variant"
        fill="currentColor"
      />
      <path
        d="M114.003 75.4772H79.0308C78.7282 75.4772 78.4381 75.357 78.2242 75.1429C78.0103 74.9289 77.8901 74.6386 77.8901 74.3359C77.8901 74.0332 78.0103 73.7429 78.2242 73.5289C78.4381 73.3148 78.7282 73.1946 79.0308 73.1946H114.003C114.306 73.1946 114.596 73.3148 114.81 73.5289C115.024 73.7429 115.144 74.0332 115.144 74.3359C115.144 74.6386 115.024 74.9289 114.81 75.1429C114.596 75.357 114.306 75.4772 114.003 75.4772Z"
        className="text-background-variant"
        fill="currentColor"
      />
      <path
        d="M94.1905 79.9867H79.0308C78.7282 79.9867 78.4381 79.8665 78.2242 79.6524C78.0103 79.4384 77.8901 79.1481 77.8901 78.8454C77.8901 78.5427 78.0103 78.2524 78.2242 78.0384C78.4381 77.8243 78.7282 77.7041 79.0308 77.7041H94.1905C94.493 77.7041 94.7831 77.8243 94.997 78.0384C95.211 78.2524 95.3311 78.5427 95.3311 78.8454C95.3311 79.1481 95.211 79.4384 94.997 79.6524C94.7831 79.8665 94.493 79.9867 94.1905 79.9867Z"
        className="text-background-variant"
        fill="currentColor"
      />
      <path
        d="M86.4435 56.8443L48.4478 24.3252H22.9214V23.8462H48.6246L86.7548 56.4802L86.4435 56.8443Z"
        className="text-on-background-main"
        fill="currentColor"
      />
      <path
        d="M22.9213 27.918C25.0367 27.918 26.7515 26.2021 26.7515 24.0855C26.7515 21.9688 25.0367 20.2529 22.9213 20.2529C20.8059 20.2529 19.0911 21.9688 19.0911 24.0855C19.0911 26.2021 20.8059 27.918 22.9213 27.918Z"
        className="text-attention-variant"
        fill="currentColor"
      />
      <path
        d="M36.5772 36.9768H1.60468C1.4549 36.9768 1.30659 36.9473 1.16821 36.89C1.02983 36.8326 0.904088 36.7486 0.798177 36.6426C0.692265 36.5366 0.608252 36.4108 0.550933 36.2723C0.493614 36.1339 0.464111 35.9855 0.464111 35.8356C0.464111 35.6857 0.493614 35.5373 0.550933 35.3989C0.608252 35.2604 0.692265 35.1346 0.798177 35.0286C0.904088 34.9226 1.02983 34.8386 1.16821 34.7812C1.30659 34.7239 1.4549 34.6943 1.60468 34.6943H36.5772C36.727 34.6943 36.8753 34.7239 37.0137 34.7812C37.1521 34.8386 37.2778 34.9226 37.3837 35.0286C37.4896 35.1346 37.5736 35.2604 37.6309 35.3989C37.6883 35.5373 37.7178 35.6857 37.7178 35.8356C37.7178 35.9855 37.6883 36.1339 37.6309 36.2723C37.5736 36.4108 37.4896 36.5366 37.3837 36.6426C37.2778 36.7486 37.1521 36.8326 37.0137 36.89C36.8753 36.9473 36.727 36.9768 36.5772 36.9768Z"
        className="text-attention-variant"
        fill="currentColor"
      />
      <path
        d="M36.5772 41.4865H1.60474C1.30222 41.4865 1.0121 41.3662 0.798191 41.1522C0.584282 40.9382 0.464111 40.6479 0.464111 40.3452C0.464111 40.0425 0.584282 39.7522 0.798191 39.5381C1.0121 39.3241 1.30222 39.2039 1.60474 39.2039H36.5772C36.8798 39.2039 37.1699 39.3241 37.3838 39.5381C37.5977 39.7522 37.7179 40.0425 37.7179 40.3452C37.7179 40.6479 37.5977 40.9382 37.3838 41.1522C37.1699 41.3662 36.8798 41.4865 36.5772 41.4865Z"
        className="text-attention-variant"
        fill="currentColor"
      />
      <path
        d="M16.7645 45.996H1.60474C1.30222 45.996 1.0121 45.8758 0.798191 45.6617C0.584282 45.4477 0.464111 45.1574 0.464111 44.8547C0.464111 44.552 0.584282 44.2617 0.798191 44.0477C1.0121 43.8336 1.30222 43.7134 1.60474 43.7134H16.7645C17.067 43.7134 17.3571 43.8336 17.571 44.0477C17.7849 44.2617 17.9051 44.552 17.9051 44.8547C17.9051 45.1574 17.7849 45.4477 17.571 45.6617C17.3571 45.8758 17.067 45.996 16.7645 45.996Z"
        className="text-attention-variant"
        fill="currentColor"
      />
      <path
        d="M96.5171 36.227L134.647 3.59302H160.35V4.07204H134.824L96.8283 36.5911L96.5171 36.227Z"
        className="text-on-background-main"
        fill="currentColor"
      />
      <path
        d="M160.35 7.66509C162.466 7.66509 164.181 5.9492 164.181 3.83254C164.181 1.71589 162.466 0 160.35 0C158.235 0 156.52 1.71589 156.52 3.83254C156.52 5.9492 158.235 7.66509 160.35 7.66509Z"
        className="text-primary-cta"
        fill="currentColor"
      />
      <path
        d="M186.394 16.5891H151.421C151.271 16.5894 151.123 16.56 150.984 16.5027C150.846 16.4455 150.72 16.3615 150.614 16.2555C150.508 16.1495 150.423 16.0236 150.366 15.885C150.309 15.7464 150.279 15.5979 150.279 15.4479C150.279 15.2979 150.309 15.1493 150.366 15.0108C150.423 14.8722 150.508 14.7463 150.614 14.6403C150.72 14.5343 150.846 14.4503 150.984 14.393C151.123 14.3358 151.271 14.3064 151.421 14.3066H186.394C186.544 14.3064 186.692 14.3358 186.831 14.393C186.969 14.4503 187.095 14.5343 187.201 14.6403C187.307 14.7463 187.392 14.8722 187.449 15.0108C187.506 15.1493 187.536 15.2979 187.536 15.4479C187.536 15.5979 187.506 15.7464 187.449 15.885C187.392 16.0236 187.307 16.1495 187.201 16.2555C187.095 16.3615 186.969 16.4455 186.831 16.5027C186.692 16.56 186.544 16.5894 186.394 16.5891Z"
        className="text-primary-cta"
        fill="currentColor"
      />
      <path
        d="M186.394 21.099H151.421C151.119 21.099 150.829 20.9788 150.615 20.7647C150.401 20.5507 150.281 20.2604 150.281 19.9577C150.281 19.655 150.401 19.3647 150.615 19.1507C150.829 18.9367 151.119 18.8164 151.421 18.8164H186.394C186.544 18.8164 186.692 18.8459 186.83 18.9033C186.969 18.9606 187.095 19.0447 187.2 19.1507C187.306 19.2567 187.39 19.3825 187.448 19.521C187.505 19.6594 187.535 19.8078 187.535 19.9577C187.535 20.1076 187.505 20.256 187.448 20.3945C187.39 20.5329 187.306 20.6588 187.2 20.7647C187.095 20.8707 186.969 20.9548 186.83 21.0121C186.692 21.0695 186.544 21.099 186.394 21.099Z"
        className="text-primary-cta"
        fill="currentColor"
      />
      <path
        d="M166.581 25.6086H151.421C151.119 25.6086 150.829 25.4883 150.615 25.2743C150.401 25.0602 150.281 24.7699 150.281 24.4672C150.281 24.1645 150.401 23.8742 150.615 23.6602C150.829 23.4462 151.119 23.3259 151.421 23.3259H166.581C166.884 23.3259 167.174 23.4462 167.388 23.6602C167.602 23.8742 167.722 24.1645 167.722 24.4672C167.722 24.7699 167.602 25.0602 167.388 25.2743C167.174 25.4883 166.884 25.6086 166.581 25.6086Z"
        className="text-primary-cta"
        fill="currentColor"
      />
    </svg>
  );
};
