import { FC } from 'react';
import { IconProps } from '.';

export const IconDocument: FC<IconProps> = ({ width = '18px', height = '18px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 14.4H12.5V12.6H5.5V14.4ZM5.5 10.8H12.5V9H5.5V10.8ZM3.75 18C3.26875 18 2.85677 17.8238 2.51406 17.4713C2.17135 17.1188 2 16.695 2 16.2V1.8C2 1.305 2.17135 0.88125 2.51406 0.52875C2.85677 0.17625 3.26875 0 3.75 0H10.75L16 5.4V16.2C16 16.695 15.8286 17.1188 15.4859 17.4713C15.1432 17.8238 14.7312 18 14.25 18H3.75ZM9.875 6.3H14.25L9.875 1.8V6.3Z"
      fill="currentColor"
    />
  </svg>
);
