'use client';

import { createContext, FC, ReactNode, useContext, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { mutate } from 'swr';
import { ToastVariant } from '../enums/toast-variant';
import { getCompanyId } from '../helpers/getCompanyId';
import {
  getUseIgnoreNetPromoterScoreRatingQueryKey,
  getUseNetPromoterScoreTriggerCheckQueryQueryKey,
  getUseSubmitNetPromoterScoreRatingQueryKey,
  useIgnoreNetPromoterScoreMutation,
  useNetPromoterScoreTriggerCheckQuery,
  useSubmitNetPromoterScoreMutation,
} from '../hooks/swr/useNetPromoterScoreHooks';
import { useToast } from '../hooks/useToast';
import { LayoutContext } from '../src/DefaultLayout';
import { NetPromoterScore } from '../src/NetPromoterScore';
import { FeatureFlagContext } from './FeatureFlagProvider';
export const NetPromoterScoreContext = createContext({});
type NetPromoterScoreProviderProps = {
  children: ReactNode;
};

export const NetPromoterScoreProvider: FC<NetPromoterScoreProviderProps> = ({ children }) => {
  const { shouldShowNetPromoterScore, setShouldShowNetPromoterScore } = useContext(LayoutContext);
  const { showToast } = useToast();
  const { user } = useAuth();
  const { flags } = useContext(FeatureFlagContext);

  const { data: netPromoterScoreTriggerCheckResponse } = useNetPromoterScoreTriggerCheckQuery();
  const netPromoterScoreTriggerCheckData =
    netPromoterScoreTriggerCheckResponse?.netPromoterScoreTriggerCheck;

  const { trigger: submitRating } = useSubmitNetPromoterScoreMutation(
    getUseSubmitNetPromoterScoreRatingQueryKey(),
  );
  const { trigger: ignoreRating } = useIgnoreNetPromoterScoreMutation(
    getUseIgnoreNetPromoterScoreRatingQueryKey(),
  );

  const getUpdatedQuestionWithAppName = (question: string): string => {
    const titleElement = document.getElementsByTagName('title')[0];
    const appName = titleElement?.textContent || 'Unique Chat';
    return question.replace('Unique Chat', appName);
  };

  useEffect(() => {
    if (netPromoterScoreTriggerCheckData?.status) {
      const delayInMs =
        (netPromoterScoreTriggerCheckData.delayInSecBeforeQuestionDisplay || 1) * 1000;
      const timer = setTimeout(() => {
        setShouldShowNetPromoterScore?.(true);
      }, delayInMs);
      return () => clearTimeout(timer);
    }
  }, [
    netPromoterScoreTriggerCheckData?.status,
    netPromoterScoreTriggerCheckData?.delayInSecBeforeQuestionDisplay,
    setShouldShowNetPromoterScore,
  ]);

  const handleSubmitRating = async (score: number) => {
    await submitRating(
      {
        input: { score, companyId: getCompanyId(user), createdBy: user?.profile.email ?? '' },
      },
      {
        onSuccess() {
          showToast?.({
            message: 'Thank you for the valued feedback!',
            variant: ToastVariant.SUCCESS,
          });
          mutate(getUseNetPromoterScoreTriggerCheckQueryQueryKey());
          setShouldShowNetPromoterScore?.(false);
        },
      },
    );
  };

  const handleIgnoreRating = async () => {
    await ignoreRating(
      {},
      {
        onSuccess() {
          mutate(getUseNetPromoterScoreTriggerCheckQueryQueryKey());
          setShouldShowNetPromoterScore?.(false);
        },
      },
    );
  };

  return (
    <NetPromoterScoreContext.Provider value={{}}>
      {children}
      {flags.FEATURE_FLAG_NET_PROMOTER_SCORE_UI_UN_7747 &&
        shouldShowNetPromoterScore &&
        netPromoterScoreTriggerCheckData?.question && (
          <NetPromoterScore
            text={getUpdatedQuestionWithAppName(netPromoterScoreTriggerCheckData.question)}
            handleSubmitRating={handleSubmitRating}
            handleIgnoreRating={handleIgnoreRating}
            shouldShow={shouldShowNetPromoterScore}
            closeModal={() => setShouldShowNetPromoterScore?.(false)}
          />
        )}
    </NetPromoterScoreContext.Provider>
  );
};
