'use client';

import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Theme } from '../@generated/graphql';
import { PRIMARY_COLOR_NAME } from '../constants/primary-color-name';
import {
  buildDefaultTheme,
  getThemeContextValues,
  getThemeFromLocalStorage,
  getThemeStyles,
  parseStaticThemeColors,
  setThemeInLocalStorage,
} from '../helpers/theme';
import { loadFaviconUrl, loadTabName, setBgColor } from '../helpers/themeDomOperations';
import { useThemeQuery } from '../hooks/swr/useThemeQuery';
import { ThemeLoadingPage } from '../src/ThemeLoadingPage';
import { FaviconTypes } from '../helpers/themeDomOperations';
import useLocalStorage from '../hooks/useLocalStorage';
import { DEFAULT_SUPPORT_EMAIL } from '../constants/default-support-email';

interface ClientThemeContextProps {
  logoNavbar?: string;
  logoHeader?: string;
  version?: string;
  supportEmail?: string;
  helpCenterLink?: string;
  settings?: { [key: string]: string };
}

export const ClientThemeContext = createContext<ClientThemeContextProps>({});

interface ClientThemeProviderProps {
  staticThemeColors: string;
  staticBackgroundColor: string;
  staticTabName?: string;
  version?: string;
  keepOriginalTabName?: boolean;
}

const BACKGROUND_COLOR_NAME = 'color-background';

export const ClientThemeProvider: FC<PropsWithChildren<ClientThemeProviderProps>> = ({
  children,
  staticThemeColors,
  staticBackgroundColor,
  staticTabName,
  version,
  keepOriginalTabName,
}) => {
  const auth = useAuth();
  const [theme, setTheme] = useState<Partial<Theme>>();
  const [fetchTheme, setFetchTheme] = useState<boolean>(false);
  const [isInitiallyLoadingTheme, setIsInitiallyLoadingTheme] = useState<boolean>(false);
  const [hasStaticThemeColors, setHasStaticThemeColors] = useState<boolean>(false);
  const { data: themeQuery, error: themeQueryError } = useThemeQuery(fetchTheme, auth);
  const [, setSupportEmail] = useLocalStorage('supportEmail', '');

  const isThemeOutdated = (timestamp: string) => {
    const twoHoursAgo = new Date();
    twoHoursAgo.setHours(twoHoursAgo.getHours() - 2);
    const parsedTimestamp = new Date(timestamp);
    return parsedTimestamp < twoHoursAgo;
  };

  useEffect(() => {
    // 1. Check if static theme data (e.g. colors and tab name) are set
    const staticTheme: Partial<Theme> = {};

    if (staticTabName) {
      staticTheme.tabName = staticTabName;
    }

    if (staticThemeColors) {
      staticTheme.colors = parseStaticThemeColors(staticThemeColors);
      setHasStaticThemeColors(true);
    }

    if (Object.keys(staticTheme).length) {
      setTheme(staticTheme);
    }

    // 2. Check if theme is stored in local storage after verifying user is authenticated
    if (!auth?.user) return;
    try {
      const localStorageTheme = getThemeFromLocalStorage(auth.user);
      if (localStorageTheme) {
        const parsedTheme = JSON.parse(localStorageTheme);
        if (!parsedTheme.settings?.hideSupportEmail) {
          setSupportEmail(theme?.supportEmail || DEFAULT_SUPPORT_EMAIL);
        } else {
          setSupportEmail('');
        }
        setTheme(parsedTheme);
        if (isThemeOutdated(parsedTheme.timestamp)) {
          setFetchTheme(true);
        }
      } else {
        // enable fetching theme from backend
        setFetchTheme(true);
        // show initial loading screen if no theme found in local storage
        setIsInitiallyLoadingTheme(true);
      }
    } catch (e) {
      setIsInitiallyLoadingTheme(false);
      switch (true) {
        case e instanceof DOMException:
          console.error('Cannot get theme from local storage');
          break;
        case e instanceof SyntaxError:
          console.error('Returned theme is not in a valid JSON format');
          break;
        default:
          console.error('Cannot get static theme.');
          break;
      }
    }
  }, [staticTabName, staticThemeColors, auth?.user]);

  useEffect(() => {
    // 3. Set theme from backend if exists
    try {
      if (themeQuery?.theme) {
        const theme = themeQuery?.theme;
        if (!theme.settings?.hideSupportEmail) {
          setSupportEmail(theme?.supportEmail || DEFAULT_SUPPORT_EMAIL);
        } else {
          setSupportEmail('');
        }
        setIsInitiallyLoadingTheme(false);
        setTheme(theme);
        setThemeInLocalStorage(auth?.user, theme);
        return;
      }
    } catch (e) {
      setIsInitiallyLoadingTheme(false);
      switch (true) {
        case e instanceof SyntaxError:
          console.error('Returned theme is not in a valid JSON format');
          break;
        default:
          console.error('Cannot set static theme.');
          break;
      }
    }
  }, [themeQuery, auth?.user]);

  useEffect(() => {
    // 4. Set default theme if no theme was set yet on the state
    // (e.g. no theme returned from backend, no local storage theme and no static theme)
    if (themeQueryError) {
      setIsInitiallyLoadingTheme(false);
      const noThemeExists = !theme && !hasStaticThemeColors && auth.isAuthenticated;
      if (noThemeExists) {
        setTheme(buildDefaultTheme());
      }
    }
  }, [themeQueryError, hasStaticThemeColors, theme, auth.isAuthenticated]);

  const getThemeColor = (colorName: string, fallbackColor: string) => {
    return theme?.colors?.find((color) => color.name === colorName)?.hexValue || fallbackColor;
  };
  loadFaviconUrl(
    theme?.favicon ? theme?.favicon || '' : getThemeColor(PRIMARY_COLOR_NAME, ''),
    theme?.favicon ? FaviconTypes.Image : FaviconTypes.Color,
  );
  setBgColor(getThemeColor(BACKGROUND_COLOR_NAME, staticBackgroundColor));

  !keepOriginalTabName && loadTabName(theme?.tabName || undefined);

  const showThemeLoadingPage =
    isInitiallyLoadingTheme && !hasStaticThemeColors && auth.isAuthenticated;

  return (
    <ClientThemeContext.Provider value={getThemeContextValues(theme, version)}>
      {showThemeLoadingPage ? (
        <ThemeLoadingPage />
      ) : (
        <>
          <style jsx global>
            {`
              ${getThemeStyles(theme)}
            `}
          </style>
          {children}
        </>
      )}
    </ClientThemeContext.Provider>
  );
};
