import { isLinkElement } from '@unique/shared-library/helpers/isLinkElement';

export const showUnsavedChangesWarning = (event: MouseEvent, shouldShow: boolean) => {
  if (!shouldShow) return;
  const eventTargetHTMLEl = event.target as HTMLElement;

  // also check if parent is a link element (this can be the case for top level navigation items)
  if (
    isLinkElement(eventTargetHTMLEl) ||
    (eventTargetHTMLEl.parentElement && isLinkElement(eventTargetHTMLEl.parentElement))
  ) {
    const linkEl = eventTargetHTMLEl.closest('a');
    const shouldLeavePage = confirm(
      'Warning: You have unsaved changes. Are you sure you want to leave the page?',
    );
    if (shouldLeavePage && linkEl?.href) {
      window.location.href = linkEl.href;
    } else {
      event.preventDefault();
    }
  }
};
