export enum Roles {
  CHAT_BASIC = 'chat.chat.basic',
  CHAT_UNLIMITED = 'chat.chat.unlimited',
  KNOWLEDGE_READ = 'chat.knowledge.read',
  KNOWLEDGE_WRITE = 'chat.knowledge.write',
  FEEDBACK_READ = 'chat.feedback.read',
  ADMIN_ALL = 'chat.admin.all',
  DEBUG_READ = 'chat.debug.read',
  CHAT_DATA_ADMIN = 'chat.data.admin',
  ADMIN_USER_MANAGEMENT_WRITE = 'admin.user-management.write',
  ADMIN_SPACE_WRITE = 'admin.space.write',
  ADMIN_APP_REPOSITORY_WRITE = 'admin.app-repository.write',
}
