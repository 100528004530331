import { GraphqlFetcherArgs, Service, useClientSWR } from '@unique/next-commons/swr';
import {
  PaginatedContentDocument,
  PaginatedContentQuery,
  PaginatedContentQueryVariables,
} from '../../@generated/graphql';
import { SWRConfiguration } from 'swr';

export function usePaginatedContentQuery(
  variables?: PaginatedContentQueryVariables,
  options?: Partial<SWRConfiguration<PaginatedContentQuery>>,
) {
  return useClientSWR<PaginatedContentQuery, PaginatedContentQueryVariables>(
    variables || variables === undefined ? getUsePaginatedContentQueryKey(variables) : null,
    options,
    Service.NODE_INGESTION,
  );
}

export function getUsePaginatedContentQueryKey(
  variables?: PaginatedContentQueryVariables,
): GraphqlFetcherArgs<PaginatedContentQueryVariables> {
  return { query: PaginatedContentDocument, variables };
}
