import { SWRConfiguration } from 'swr';
import {
  FolderTreeDocument,
  FolderTreeQuery,
  FolderTreeQueryVariables,
} from '../../@generated/graphql';
import { GraphqlFetcherArgs, Service, useClientSWR } from '@unique/next-commons/swr';

export function useFolderTreeQuery(
  variables?: FolderTreeQueryVariables,
  options?: Partial<SWRConfiguration<FolderTreeQuery>>,
) {
  return useClientSWR<FolderTreeQuery, FolderTreeQueryVariables>(
    // If variables exist OR are not passed to the function, execute query. Otherwise (e.g. is null), don't execute.
    getUseFolderTreeQueryKey(variables),
    options,
    Service.NODE_SCOPE_MANAGEMENT,
  );
}

export function getUseFolderTreeQueryKey(
  variables?: FolderTreeQueryVariables,
): GraphqlFetcherArgs<FolderTreeQueryVariables> {
  return { query: FolderTreeDocument, variables };
}
