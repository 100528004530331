import { useEffect, useRef } from 'react';

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Accessing the ref's current property here is safe because it is only used to return the previous value,
  // which is updated in the useEffect hook after each render. This does not cause any side effects or re-renders.
  // eslint-disable-next-line react-compiler/react-compiler
  return ref.current;
};
