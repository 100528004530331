
export const generateContactAdminLink = ({
  supportEmail,
  subject,
  body,
}: {
  supportEmail?: string;
  subject: string;
  body: string;
}) => {
  return `mailto:${supportEmail}?subject=${subject}&body=${body}`;
};
