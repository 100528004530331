// Assignments are similar to roles but generated based on database data.
export enum Assignments {
  // Space manager is true if user is assigned to at least one space as manager
  SPACE_MANAGER = 'space.manager',
}

export function isAssignements(x: string): boolean {
  return typeof x === 'string' && Object.keys(Assignments).includes(x as Assignments);
}

export function getAssignments(x: unknown): Assignments[] {
  const assignements: Assignments[] = [];
  if (!Array.isArray(x)) return [];
  x.forEach((assignement) => {
    if (isAssignements(assignement)) {
      assignements.push(Assignments[assignement]);
    }
  });
  return assignements;
}
