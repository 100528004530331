import { FC } from 'react';
import { IconProps } from '.';

export const IconTranslate: FC<IconProps> = ({ width = '18px', height = '18px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.7128 12.1195C9.82952 11.8135 9.75449 11.465 9.52105 11.227L7.77858 9.476L7.80359 9.4505C9.25426 7.8015 10.2881 5.906 10.8967 3.9H12.5141C12.9643 3.9 13.3395 3.5175 13.3395 3.0585V3.0415C13.3395 2.5825 12.9643 2.2 12.5141 2.2H7.50345V1.35C7.50345 0.8825 7.12828 0.5 6.66974 0.5C6.21119 0.5 5.83602 0.8825 5.83602 1.35V2.2H0.82538C0.375173 2.2 0 2.5825 0 3.0415C0 3.509 0.375173 3.883 0.82538 3.883H9.31262C8.75403 5.532 7.87029 7.0875 6.66974 8.4475C5.99443 7.691 5.4275 6.8665 4.95228 5.9995C4.81889 5.753 4.57711 5.6 4.30198 5.6C3.72672 5.6 3.35988 6.2375 3.64334 6.7475C4.16859 7.708 4.81055 8.626 5.56089 9.476L1.91755 13.1395C1.58406 13.471 1.58406 14.015 1.91755 14.3465C2.2427 14.678 2.76794 14.678 3.10143 14.3465L6.66974 10.7L8.35385 12.417C8.77904 12.8505 9.50438 12.689 9.7128 12.1195ZM13.7563 7.3C13.2561 7.3 12.8059 7.6145 12.6308 8.099L9.57107 16.429C9.37098 16.9475 9.75449 17.5 10.2964 17.5C10.6216 17.5 10.9134 17.296 11.0301 16.9815L11.7721 14.95H15.7322L16.4826 16.9815C16.5993 17.2875 16.8911 17.5 17.2163 17.5C17.7582 17.5 18.1417 16.9475 17.9499 16.429L14.8902 8.099C14.7068 7.6145 14.2566 7.3 13.7563 7.3ZM12.4057 13.25L13.7563 9.5695L15.107 13.25H12.4057Z"
      fill="currentColor"
    />
  </svg>
);
