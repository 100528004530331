import { FC } from 'react';
import { IconProps } from '.';

export const IconFilePlain: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  // Define the viewBox dimensions
  const viewBoxWidth = 32;
  const viewBoxHeight = 32;

  // Define the bounding box dimensions of the paths
  const pathWidth = 24; // Example width of the path
  const pathHeight = 32; // Example height of the path

  // Calculate the translation needed to center the path
  const translateX = (viewBoxWidth - pathWidth) / 2;
  const translateY = (viewBoxHeight - pathHeight) / 2;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform={`translate(${translateX}, ${translateY})`}>
        <path
          d="M0 29.7739V2.22609C0 0.996653 0.943347 0 2.10703 0H14.4858L23.9674 10.0174V29.7739C23.9674 31.0033 23.0241 32 21.8604 32H2.10703C0.943348 32 0 31.0033 0 29.7739Z"
          fill="currentColor"
          className="text-background"
        />
        <path
          d="M14.6211 7.77778V0L23.9996 10H16.7052C15.5542 10 14.6211 9.00508 14.6211 7.77778Z"
          fill="currentColor"
          className="text-background-variant"
        />
      </g>
    </svg>
  );
};
