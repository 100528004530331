export enum ColumnItemType {
  Folder = 'folder',
  SyncedFolder = 'syncedFolder',
  File = 'file',
}

export const getColumnItemType = (externalId?: string | null, isFolder: boolean = false) => {
  if (!isFolder) return ColumnItemType.File;
  if (externalId) return ColumnItemType.SyncedFolder;
  return ColumnItemType.Folder;
};
