export interface IconProps {
  width?: string;
  height?: string;
  className?: string;
}

export * from './IconAdd';
export * from './IconAddColumn';
export * from './IconAddSlim';
export * from './IconAlert';
export * from './IconAlignCenter';
export * from './IconAlignLeft';
export * from './IconAnalytics';
export * from './IconApps';
export * from './IconArrowDiagonal';
export * from './IconArrowDown';
export * from './IconArrowUp';
export * from './IconAssistant';
export * from './IconAssistantModule';
export * from './IconBenchmarking';
export * from './IconBook';
export * from './IconBotError';
export * from './IconBotErrorGeneric';
export * from './IconBotGlasses';
export * from './IconBotSimple';
export * from './IconBotSleeping';
export * from './IconBotStatusCode';
export * from './IconCalendar';
export * from './IconCaret';
export * from './IconCaretDown';
export * from './IconCaretUp';
export * from './IconCheck';
export * from './IconCheckmark';
export * from './IconChevronLeft';
export * from './IconClose';
export * from './IconCloseSlim';
export * from './IconConfigure';
export * from './IconConfluence';
export * from './IconCopy';
export * from './IconCreateSpace';
export * from './IconCSVFile';
export * from './IconCustomModule';
export * from './IconDelete';
export * from './IconDeleteModal';
export * from './IconDisabled';
export * from './IconDocument';
export * from './IconDownload';
export * from './IconDownloadLine';
export * from './IconEdit';
export * from './IconEmail';
export * from './IconEmptySearch';
export * from './IconExcelFile';
export * from './IconExpiredFile';
export * from './IconExternalFile';
export * from './IconEyeClosed';
export * from './IconEyeOpen';
export * from './IconFeedback';
export * from './IconFile';
export * from './IconFileAdd';
export * from './IconFileLoading';
export * from './IconFileNotFound';
export * from './IconFilePlain';
export * from './IconFileUploadError';
export * from './IconFilter';
export * from './IconFolder';
export * from './IconFolderCloud';
export * from './IconFolderUnique';
export * from './IconGroup';
export * from './IconHamburger';
export * from './IconHelp';
export * from './IconHelpCenter';
export * from './IconHierarchy';
export * from './IconHtmlFile';
export * from './IconImportDocuments';
export * from './IconInfo';
export * from './IconJpgFile';
export * from './IconKey';
export * from './IconLater';
export * from './IconLightBulb';
export * from './IconLoading';
export * from './IconLocalUpload';
export * from './IconLogo';
export * from './IconLogoFavicon';
export * from './IconLogout';
export * from './IconManageAIModules';
export * from './IconMdFile';
export * from './IconMeatballsMenu';
export * from './IconMenu';
export * from './IconNewChat';
export * from './IconNewFolder';
export * from './IconNewWindow';
export * from './IconNoAccess';
export * from './IconNoPrompts';
export * from './IconOffline';
export * from './IconPaperPlane';
export * from './IconPdfFile';
export * from './IconPngFile';
export * from './IconPptFile';
export * from './IconPuzzle';
export * from './IconRefresh';
export * from './IconReportType';
export * from './IconRocket';
export * from './IconScope';
export * from './IconSearch';
export * from './IconSearchThick';
export * from './IconShapeChat';
export * from './IconShapeCollapseMenu';
export * from './IconShapeExpandMenu';
export * from './IconShapeHeadEngine';
export * from './IconSharepoint';
export * from './IconSpace';
export * from './IconSpaceAccess';
export * from './IconStar';
export * from './IconStarOutline';
export * from './IconStop';
export * from './IconSync';
export * from './IconTermsAndConditions';
export * from './IconTextFile';
export * from './IconThumbsDown';
export * from './IconThumbsDownFeedback';
export * from './IconThumbsUp';
export * from './IconThumbsUpFeedback';
export * from './IconTipsAndUpdates';
export * from './IconTranslate';
export * from './IconTunings';
export * from './IconUpload';
export * from './IconUploadArrow';
export * from './IconUploadInChat';
export * from './IconViewFiles';
export * from './IconViewFolders';
export * from './IconWarning';
export * from './IconWordFile';
export * from './IconWorld';

