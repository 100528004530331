import { LayoutContext } from '@unique/shared-library';
import cn from 'classnames';
import { Link } from "react-router-dom";
import { FC, MouseEvent, ReactNode, useContext } from 'react';

export interface SecondLevelNavigationProps {
  children?: ReactNode;
  logo?: ReactNode;
  isExpanded: boolean;
  logoUrl?: string;
  secondLevelClassname?: string;
  secondLevelFooter?: ReactNode;
  handleClickItem: (e: MouseEvent<HTMLDivElement>) => void;
}

export const SecondLevelNavigation: FC<SecondLevelNavigationProps> = ({
  logo,
  children,
  isExpanded,
  logoUrl = '/',
  secondLevelClassname = '',
  secondLevelFooter,
  handleClickItem,
}) => {
  const { secondLevelNavContainerId } = useContext(LayoutContext);
  return (
    <div
      className={cn({
        'bg-secondary text-on-secondary absolute bottom-0 left-[64px] top-0 z-[55] ml-[-325px] flex w-[260px] min-w-[260px] flex-col shadow-2xl transition-all sm:relative sm:left-auto sm:ml-[-260px]':
          true,
        '!ml-0': isExpanded,
      })}
    >
      <Link to={logoUrl} className="block pb-2 pl-4 pr-6 pt-6">
        {logo}
      </Link>

      <div
        className={cn({
          'global-scrollbar scrollbar-gutter-stable min-w-[260px] grow overflow-y-auto hover:overflow-y-auto sm:overflow-hidden':
            true,
          [secondLevelClassname]: true,
        })}
        onClick={(e: MouseEvent<HTMLDivElement>) => handleClickItem(e)}
        id={secondLevelNavContainerId}
      >
        {children}
      </div>

      {secondLevelFooter && (
        <div className="bg-secondary flex w-full items-center p-4 pb-10">{secondLevelFooter}</div>
      )}
    </div>
  );
};
