import cn from 'classnames';
import { CSSProperties, FC } from 'react';

export enum ProgressBarVariant {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
}

interface ProgressBarProps {
  completed?: number;
  variant?: ProgressBarVariant;
  title?: string;
  className?: string;
  customProgressBarStyle?: CSSProperties;
  customProgressBgStyle?: CSSProperties;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  completed = 0,
  variant = ProgressBarVariant.DEFAULT,
  title,
  className = '',
  customProgressBarStyle = {},
  customProgressBgStyle = {},
}) => {
  return (
    <div
      className={cn({
        'shadow-inset text-on-background-main relative flex h-4 w-full items-center overflow-hidden rounded-lg':
          true,
        [className]: true,
      })}
      title={title}
      style={customProgressBgStyle}
    >
      <div
        className={cn({
          'transition-width absolute left-0 top-0 h-full bg-gradient-to-r duration-500 ease-in-out':
            true,
          'bg-info': variant === ProgressBarVariant.DEFAULT,
          'bg-error-dark': variant === ProgressBarVariant.ERROR,
          'bg-success-dark': variant === ProgressBarVariant.SUCCESS,
        })}
        style={{ width: `${completed}%`, ...customProgressBarStyle }}
      />
    </div>
  );
};
