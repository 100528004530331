'use client';

import { ButtonIcon } from '@unique/component-library';
import { IconBotErrorGeneric, IconBotStatusCode, IconLogo } from '@unique/icons';
import { SignoutRedirectArgs } from 'oidc-client-ts';
import { FC, useState } from 'react';
import { generateContactAdminLink } from '../helpers/generateContactAdminLink';
import useLocalStorage from '../hooks/useLocalStorage';
import { serializeError } from 'serialize-error';

interface ErrorPageProps {
  title?: string;
  description?: string;
  statusCode?: number;
  showTryAgain?: boolean;
  showReturnHome?: boolean;
  basePath?: string;
  logout?: (args?: SignoutRedirectArgs) => void;
  showSupportLink?: boolean;
  selfUrl?: string;
  error?: Partial<Error>;
}

const backgroundColor = '#F6F6F6';
const primaryColor = '#3E3E3E';
const secondaryColor = '#D8D8D8';
const logoColor = '#A0A0A0';
const linkColor = '#0E8BB4';

export const ErrorPage: FC<ErrorPageProps> = ({
  title,
  description,
  statusCode,
  showTryAgain = false,
  logout = undefined,
  basePath,
  showReturnHome = true,
  showSupportLink = true,
  selfUrl,
  error,
}) => {
  const handleClickReturnHome = () => (window.location.href = basePath || '/');
  const handleClickTryAgain = () => typeof window !== 'undefined' && window.location.reload();
  const [supportEmail] = useLocalStorage('supportEmail', '');
  const showModeDetails = !!error;
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!supportEmail) showSupportLink = false;

  return (
    <div
      style={{ backgroundColor }}
      className="fixed left-0 top-0 z-[60] flex h-screen w-screen flex-col items-center justify-center px-4 py-16"
    >
      <div className="max-w-xl text-center" style={{ color: primaryColor }}>
        {statusCode ? (
          <IconBotStatusCode width="125" height="125" statusCode={statusCode} className="m-auto" />
        ) : (
          <IconBotErrorGeneric className="m-auto" />
        )}
        <div className="title-l pb-2 pt-4 !font-bold">{title ?? 'Something went wrong.'}</div>
        <div className="body-1 mb-7 whitespace-pre-wrap text-center">
          {description ?? (
            <>
              We apologize for the inconvenience, but it seems like there was an unexpected issue
              with our software.
            </>
          )}
        </div>
        <div className="flex justify-center gap-2">
          {showTryAgain && (
            <ButtonIcon
              onClick={handleClickTryAgain}
              style={{ backgroundColor: primaryColor, color: 'white' }}
            >
              Try Again
            </ButtonIcon>
          )}
          {showModeDetails && (
            <ButtonIcon
              onClick={() => setIsModalOpen(true)}
              style={{ backgroundColor: secondaryColor }}
            >
              See error details
            </ButtonIcon>
          )}
          {showReturnHome && (
            <ButtonIcon
              onClick={handleClickReturnHome}
              style={{ backgroundColor: primaryColor, color: 'white' }}
            >
              Return to Home
            </ButtonIcon>
          )}
          {!!logout && !!selfUrl && (
            <ButtonIcon
              className="subtitle-2"
              onClick={() => logout({ post_logout_redirect_uri: selfUrl })}
              style={{ backgroundColor: primaryColor, color: 'white' }}
            >
              Log out
            </ButtonIcon>
          )}
        </div>
        {showSupportLink && (
          <p className="body-1 mt-14 text-center">
            If you have any urgent questions or if the issue persists, you can
            <a
              className="mx-1.5 cursor-pointer underline"
              href={generateContactAdminLink({
                supportEmail,
                subject: 'Report Issue: General error page',
                body: 'Report Issue: General error page',
              })}
              style={{ color: linkColor }}
            >
              Contact Our Support Team
            </a>
            for further assistance.
          </p>
        )}
      </div>

      <div className="absolute bottom-6 w-full" style={{ color: logoColor }}>
        <IconLogo className="mx-auto" width="95" />
      </div>

      {isModalOpen && (
        <>
          <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center p-2">
            <div
              className="z-50 flex max-h-80 max-w-[80%] flex-col rounded-2xl"
              style={{ backgroundColor }}
            >
              <pre className="overflow-auto p-4 text-sm">
                {serializeError(error)?.message}
                <br />
                {serializeError(error)?.stack}
              </pre>
              <div className="flex justify-end px-4 pb-2">
                <ButtonIcon onClick={() => setIsModalOpen(false)}>Close</ButtonIcon>
              </div>
            </div>
            <div
              className="absolute bottom-0 left-0 right-0 top-0 z-0"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
              onClick={() => setIsModalOpen(false)}
            >
              {/* empty div for clickable background */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
