import { FC, useContext } from 'react';

import { IconEmail, IconHelpCenter, IconLogout, IconTermsAndConditions } from '@unique/icons';
import { ClientThemeContext, LayoutContext, useScreens } from '@unique/shared-library';
import { useAuth } from 'react-oidc-context';
import { HelpMenu } from './HelpMenu';
import { UserMenu } from './UserMenu';
import { compact } from 'lodash';

interface MenuProps {
  userName: string;
  userEmail: string;
  basePath?: string;
  selfUrl?: string;
}

export const HeaderMenuOptions: FC<MenuProps> = ({ userName, userEmail, basePath, selfUrl }) => {
  const { supportEmail, helpCenterLink } = useContext(ClientThemeContext);
  const { setShouldShowTermsAndConditions } = useContext(LayoutContext);
  const { signoutRedirect } = useAuth();
  const { isMobile } = useScreens();

  const helpMenuSections = [
    compact([
      supportEmail && {
        title: 'Email Support Team',
        icon: <IconEmail />,
        handleClick: () => {
          window.open(`mailto:${supportEmail}`);
        },
      },
      helpCenterLink && {
        title: 'Help Center',
        icon: <IconHelpCenter />,
        handleClick: () => window.open(helpCenterLink, '_blank'),
      },
    ]),
  ];

  const userMenuSections = [
    [
      {
        title: 'Terms & Conditions',
        icon: <IconTermsAndConditions />,
        handleClick: () => setShouldShowTermsAndConditions && setShouldShowTermsAndConditions(true),
      },
      {
        title: 'Logout',
        icon: <IconLogout />,
        handleClick: () => {
          signoutRedirect({ post_logout_redirect_uri: selfUrl });
        },
      },
    ],
  ];

  return (
    <div className="flex h-full items-center">
      {helpMenuSections[0].length > 0 && (
        <HelpMenu userName={userName} userEmail={userEmail} menuSections={helpMenuSections} />
      )}
      <UserMenu
        userName={userName}
        userEmail={userEmail}
        basePath={basePath}
        menuSections={
          isMobile ? [helpMenuSections[0].concat(userMenuSections[0])] : userMenuSections
        }
      />
    </div>
  );
};
