'use client';
import { useEffect, useRef } from 'react';
import { CustomMagicTableRow, MagicTableRefHandles } from '..';
import { ColDef } from 'ag-grid-community';
import {
  calculateRowHeight,
  generateEmptyColumns,
  generateEmptyLoadingRows,
  generateEmptyRows,
  TOTAL_COLUMNS,
} from './helpers';
import Skeleton from '../../Skeleton';
import { MagicTableRow } from '@unique/shared-library/@generated/graphql';

export const useMagicTable = ({
  tableData,
  isLoading,
}: {
  tableData: MagicTableRow[];
  isLoading: boolean;
}) => {
  const tableRef = useRef<MagicTableRefHandles>(null);
  const hasInitialized = useRef(false);

  useEffect(() => {
    if (isLoading) {
      const { columns, rows } = generateEmptyLoadingRows(26, <Skeleton className="mt-2" />);
      updateColumnDefs(columns);
      updateRowDefs(rows);
      return;
    }
    if (!isLoading && tableData?.length && !hasInitialized.current) {
      hasInitialized.current = true;
      const columnDefs = generateEmptyColumns(TOTAL_COLUMNS);
      updateColumnDefs(columnDefs);

      // Create a lookup map for fast access to tableData by row ID
      // and Calculate the height of each row based on the longest content in the row
      const tableDataMap = new Map<number, MagicTableRow>(
        tableData?.map((row) => {
          const computedHeight = calculateRowHeight(row);
          const height = computedHeight < 30 ? 30 : computedHeight;
          return [Number(row.id), { ...row, rowHeight: height }];
        }),
      );

      // Define function to create a row with either actual data or empty fields
      const createRowData = (id: number, data: { [key: string]: string } = {}) => {
        return columnDefs.reduce(
          (acc: { [key: string]: string }, colDef) => {
            acc[colDef.field] = data[colDef.field] || '';
            acc['rowHeight'] = data.rowHeight?.toString() || '30';
            return acc;
          },
          { id: id.toString() },
        );
      };

      // Calculate the maximum row ID
      const maxRowId = Math.max(...tableData.map((row) => Number(row.id)), 0);

      // Generate all rows, filling in gaps with empty rows
      const allRowData = Array.from({ length: maxRowId + 101 }, (_, index) => {
        const rowId = index + 1;
        const rowData = tableDataMap.get(rowId) || {}; // Get data or default to empty object
        return createRowData(rowId, rowData);
      });

      updateRowDefs(allRowData);
    } else if (!tableData?.length) {
      const columnDefs = generateEmptyColumns(TOTAL_COLUMNS);
      const rowDefs = generateEmptyRows(100, 1);
      updateColumnDefs(columnDefs);
      updateRowDefs(rowDefs);
    }
  }, [tableData, isLoading]);

  const updateColumnDefs = (newColDefs: ColDef[]) => {
    tableRef.current?.updateColumnDefs(newColDefs);
  };

  const updateRowDefs = (newRowData: { [x: string]: string }[]) => {
    tableRef.current?.updateRowData(newRowData);
  };

  const stopEditingCell = () => {
    tableRef.current?.stopEditingCell();
  };

  const updateCellValues = (rowNode: number, columnNode: string, newValue: string) => {
    tableRef.current?.updateCellValues(rowNode, columnNode, newValue);
  };

  const getTableData = () => {
    const result = tableRef.current?.getTableData?.() ?? [];
    return result;
  };

  const exportAsCSV = (fileName: string) => {
    tableRef.current?.exportAsCSV(fileName);
  };

  const getLastOccupiedColumn = () => {
    const result = tableRef.current?.getLastOccupiedColumn?.();
    return result;
  };

  const getColumnIndex = (columnId: string) => {
    const result = tableRef.current?.getColumnIndex?.(columnId);
    return result;
  };

  const getColumnAtIndex = (index: number) => {
    const result = tableRef.current?.getColumnAtIndex?.(index);
    return result;
  };

  const setRowUpdate = (rowIndex: number, rowData: CustomMagicTableRow[]) => {
    tableRef.current?.setRowUpdate(rowIndex, rowData);
  };

  return {
    tableRef,
    updateColumnDefs,
    updateRowDefs,
    getTableData,
    exportAsCSV,
    stopEditingCell,
    updateCellValues,
    getLastOccupiedColumn,
    getColumnIndex,
    getColumnAtIndex,
    setRowUpdate,
  } as const;
};
