import { FC } from 'react';
import { IconProps } from '.';

export const IconFileAdd: FC<IconProps> = ({ width = '16px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0625 14.4H8.9375V11.7H11.75V9.9H8.9375V7.2H7.0625V9.9H4.25V11.7H7.0625V14.4ZM2.375 18C1.85938 18 1.41797 17.8238 1.05078 17.4713C0.683594 17.1188 0.5 16.695 0.5 16.2V1.8C0.5 1.305 0.683594 0.88125 1.05078 0.52875C1.41797 0.17625 1.85938 0 2.375 0H9.875L15.5 5.4V16.2C15.5 16.695 15.3164 17.1188 14.9492 17.4713C14.582 17.8238 14.1406 18 13.625 18H2.375Z"
        fill="currentColor"
      />
    </svg>
  );
};
