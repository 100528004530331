import { FC } from 'react';
import { IconFilePlain, IconLoading, IconProps } from '.';

export const IconFileLoading: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  const iconLoadingWidth = `${parseInt(width) / 3}px`;
  const iconLoadingHeight = `${parseInt(height) / 3}px`;
  return (
    <div className={`relative flex items-center justify-center h-[${height}] w-[${width}]`}>
      <IconLoading className="mt-2" width={iconLoadingWidth} height={iconLoadingHeight} />
      <IconFilePlain width="auto" height={height} />
    </div>
  );
};
