'use client';

import { FC } from 'react';
import { Spinner } from './Spinner';

export const LoadingScreen: FC = () => {
  return (
    <div className="absolute left-0 top-0 flex h-screen w-full items-center justify-center">
      <Spinner size={24} />
    </div>
  );
};